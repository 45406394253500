<template>
  <div>
    <div class="staff">
      <div class="left-top">
        <el-button type="primary" @click="backpage()" style="margin-right: 20px"
          >返回</el-button
        >
        <span style="font-size: 18px; font-weight: 400; color: #333"
          >反馈列表</span
        >
        <!-- <el-button type="primary">提交</el-button> -->
        <el-divider></el-divider>
        <div class="tubiao">
          <b><i></i>有帮助: {{ useful }}</b
          ><b><i class="no"></i>没有帮助: {{ unuseful }}</b>
        </div>
      </div>
      <el-table
        border
        :data="PageConfigure"
        row-key="CategoryGuid"
        style="width: 100%"
      >
        <el-table-column
          fixed
          label="序号"
          width="100"
          type="index"
          align="center"
        >
        </el-table-column>
        <el-table-column label="反馈原因" prop="Reason" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.Reason == '' || scope.row.Reason == null"
              >-</span
            >
            <span v-show="scope.row.Reason">{{ scope.row.Reason }}</span>
          </template>
        </el-table-column>
        <el-table-column label="补充内容" prop="Remark" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.Remark == '' || scope.row.Remark == null"
              >-</span
            >
            <span v-show="scope.row.Remark">{{ scope.row.Remark }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="反馈时间"
          width="180"
          prop="CreateTime"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total"
          :page-size="Params.PageSize"
          background
          :total="Params.TotalCount"
        >
        </el-pagination>
      </div>

      <!-- 编辑 -->
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  name: "Feedpage",
  components: {
    quillEditor,
  },

  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "100px",
      useful: 0,
      unuseful: 0,
      Rowcontent: {
        EditionId: "",
        Edition: "",
        Content: "",
        OnlineTime: "",
        IsRefash: false,
        IsDelete: false,
        force: "",
        radio: "",
      },
      PageConfigure: [], //列表分页数据
      Params: {
        QuestionId: Number,
        pageIndex: 1,
        pageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
      },
      IsApp: false,
      IsEdit: false,
      pageName: "",
      deleteStatus: false,
      //选择日期
      pickerOptions: {
        disabledDate(time) {
          return time.getDate();
        },
      },
      dialongtitle: "新增版本",
      addpage: false,
      //解决重复点击问题
      isDisabled: false,

      //富文本编辑器
      editorOption: {
        modules: {
          toolbar: [
            // [{ 'size': ['small',false, 'large', 'huge'] }],//字体大小
            [{ color: [] }], //字体颜色
            [{ align: [] }],
          ],
        },
      },
      str: "",
    };
  },
  created() {},
  mounted() {
    this.searchDate();
  },

  computed: {},

  methods: {
    changetime() {
      let addtime = this.Rowcontent.OnlineTime;
      let newtime = addtime.setHours(addtime.getHours() + 8);
      this.Rowcontent.OnlineTime = new Date(newtime);
      console.log(this.Rowcontent.OnlineTime);
    },

    //转码

    //获取数据
    searchDate() {
      this.Params.QuestionId = this.$route.query.id;
      var _this = this;
      this.$get(_this.$api.GetReplyPageListByQuestionId, { ...this.Params })
        .then((res) => {
          console.log(res);
          _this.PageConfigure = res.Data.ReplyPageResult.Data;
          console.log(_this.PageConfigure);
          _this.Params.TotalCount = res.Data.ReplyPageResult.TotalNumber;
          _this.Params.TotalPage = res.Data.ReplyPageResult.TotalPage;
          _this.useful = res.Data.UsefulCount;
          _this.unuseful = res.Data.UnUsefulCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backpage() {
      this.$router.push({ path: "/Qadministration" });
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.pageIndex = val;
      console.log(this.Params.PageIndex);
      this.searchDate();
    },
    handleSizeChange(val) {
      this.Params.pageSize = val;
      console.log(val);
      this.searchDate();
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .backSpan {
    padding: 0px 20px;
    cursor: pointer;
  }

  .left-top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    b {
      padding-right: 40px;
    }

    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      background: url('../../../src/assets/images/use.png');
      background-size: cover;
      margin-right: 10px;
      // float: left
    }

    i.no {
      background: url('../../../src/assets/images/unuse.png');
      background-size: cover;
    }
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  span.isedit {
    position: absolute;
    top: 10px;
    left: 185px;
    font-size: 22px;
    color: #fff;
  }

  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      font-size: 22px;
      color: #fff;
      line-height: 0px;
      text-align: center;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }
}
</style>